const moduleName = 'header';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-header')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const header = self;
		const fixedLine = header.querySelector('.js-header-fixed-line');
		const openBtn = header.querySelector('.js-header-open-button');
		const closeBtn = header.querySelector('.js-header-close-button');
		const popupOpenBtn = header.querySelector('.js-header-popup-open-button');

		fixLine();
		window.addEventListener('scroll', fixLine);

		openBtn.addEventListener('click', showHeaderMenu);

		document.addEventListener('click', (event) => {
			if (!event.target.closest('.js-header') && header.classList.contains('_open')) {
				hideHeaderMenu();
			}
		});

		closeBtn.addEventListener('click', hideHeaderMenu);

		popupOpenBtn.addEventListener('click', () => {
			header.classList.remove('_open');
		});



		function showHeaderMenu() {
			header.classList.add('_open');
			overlay().show();
		}

		function hideHeaderMenu() {
			header.classList.remove('_open');
			overlay().hide();
		}

		function fixLine() {
			const scrollY = window.scrollY || document.documentElement.scrollTop;
			const headerHeight = header.offsetHeight;

			if (scrollY > headerHeight) {
				fixedLine.classList.add('_visible');
			} else {
				fixedLine.classList.remove('_visible');
			}
		}		
	});
	
};