const moduleName = 'quotes';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-quotes')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const quotes = self;
		const quotesPrevBtn = quotes.querySelector('.js-quotes-prev');
		const quotesNextBtn = quotes.querySelector('.js-quotes-next');

		const quotesSlider = new Swiper(quotes, {
			loop: true,
			autoplay: {
				delay: 5000
			},
			navigation: {
				prevEl: quotesPrevBtn,
				nextEl: quotesNextBtn
			}
		});
	});
	
};