const moduleName = 'nav';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const nav = self;
		const submenuHolders = nav.querySelectorAll('.js-nav-submenu-holder');

		Array.from(submenuHolders).forEach((submenuHolder) => {
			const submenuToggle = submenuHolder.querySelector('.js-nav-submenu-toggle');
			const submenu = submenuHolder.querySelector('.js-nav-submenu');
			let windowWidth = document.documentElement.clientWidth;

			submenuHolder.addEventListener('mouseenter', () => {
				if (document.documentElement.clientWidth < 1025) return;

				submenuHolder.classList.add('_open');
			});

			submenuHolder.addEventListener('mouseleave', () => {
				if (document.documentElement.clientWidth < 1025) return;

				submenuHolder.classList.remove('_open');
			});

			submenuToggle.addEventListener('click', () => {
				if (submenuHolder.classList.contains('_open')) {
					submenu.style.height = submenu.offsetHeight + 'px';

					setTimeout(() => {
						submenu.style.height = 0;
					}, 0);

					setTimeout(() => {
						submenuHolder.classList.remove('_open');
						submenu.style.height = '';
					}, 200);
				} else {
					const submenuHeight = submenu.offsetHeight;

					submenu.style.height = 0;

					setTimeout(() => {
						submenuHolder.classList.add('_open');
						submenu.style.height = submenuHeight + 'px';
					}, 0);

					setTimeout(() => {
						submenu.style.height = '';
					}, 200);
				}
			});

			window.addEventListener('resize', () => {
				if (document.documentElement.clientWidth >= 1025 && windowWidth < 1025 || 
					document.documentElement.clientWidth < 1025 && windowWidth >= 1025) {
					submenuHolder.classList.remove('_open');
				}
			});
		});
	});
	
};