const moduleName = 'ytVideo';

module.exports = () => {

	if (document.querySelector('.js-yt-video')) {
		if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
			const scriptElement = document.createElement('script');
			const firstScriptElement = document.getElementsByTagName("script")[0];

	        scriptElement.src = 'https://www.youtube.com/iframe_api';
	        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
		}

		if (typeof onYouTubeIframeAPIReady === 'undefined') {
	        window.onYouTubeIframeAPIReady = () => {
        		Array.from(document.querySelectorAll('.js-yt-video')).forEach((self) => {
					if (self.dataset[moduleName + 'Init'] === 'true') return;
					self.dataset[moduleName + 'Init'] = true;

					const holder = self;
					const player = holder.querySelector('.js-yt-video-player');
					const id = holder.getAttribute('data-id');
					const ytPlayer = new YT.Player(player, {
							videoId: id,
							host: 'https://www.youtube.com'
						});
				});
	        }
		} else {
			onYouTubeIframeAPIReady();
		}
	}	
};