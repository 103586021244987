const moduleName = 'clients';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-clients')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const clients = self;

		const clientsSlider = new Swiper(clients, {
			loop: true,
			slidesPerView: 'auto',
			autoplay: {
				delay: 5000
			}
		});
	});
	
};